<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Paciente</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-5">
                        <label for="Name"> CPF: </label>
                        <input
                          autocomplete="new-password"
                          id="Name"
                          v-model="entity.cpf"
                          placeholder=""
                          type="tel"
                          class="form-control"
                          v-mask="'999.999.999-99'"
                        />
                      </div>

                      <div class="col-md-1 mt-2">
                        <button
                          class="btn btn-primary"
                          type="button"
                          @click="buscarCPF"
                        >
                          <i class="fas fa-search"></i>
                        </button>
                      </div>

                      <div class="form-group col-md-5">
                        <label for="Name"> CNS: </label>

                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.cns"
                          placeholder=""
                        />
                      </div>

                      <div class="col-md-1 mt-2">
                        <button class="btn btn-primary" type="button">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>

                      <div class="form-group col-md-4">
                        <label for="Name">
                          <span class="required">*</span> Nome do Paciente:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.nome_paciente"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-4">
                        <label for="Name"> Nome do Pai: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.nome_pai"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-4">
                        <label for="Name"> Nome da Mãe: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.nome_mae"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Sexo:
                        </label>
                        <select class="form-control" v-model="entity.sexo">
                          <option value="M">Masculino</option>
                          <option value="F">Feminino</option>
                        </select>
                      </div>

                      <div class="form-group col-md-3">
                        <label for="Name">
                          <span class="required">*</span> Data de Nascimento:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="date"
                          id="Name"
                          class="form-control"
                          v-model="entity.data_nascimento"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-3">
                        <label for="Name"> Telefone: </label>
                        <input
                          type="text"
                          id="telefone-celular"
                          class="form-control"
                          v-model="entity.telefone"
                        />
                      </div>

                      <div class="form-group col-md-3">
                        <label> Cor/Raça</label>
                        <select class="form-control" v-model="entity.raca">
                          <option value="BRANCA">BRANCA</option>
                          <option value="PRETA">PRETA</option>
                          <option value="PARDA">PARDA</option>
                          <option value="AMARELA">AMARELA</option>
                          <option value="INDÍGENA">INDÍGENA</option>
                        </select>
                      </div>

                      <div class="form-group col-md-4">
                        <label for="Name"> Nome Social: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.nome_social"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-4">
                        <label for="Name"> Altura: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.altura"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-4">
                        <label for="Name"> Idade: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.idade"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-3">
                        <label for="Name"> CEP: </label>
                        <input
                          autocomplete="new-password"
                          id="Name"
                          v-model="entity.cep"
                          placeholder=""
                          type="tel"
                          class="form-control"
                          v-on:keyup="buscar"
                          v-mask="'99999-999'"
                        />
                      </div>

                      <div class="form-group col-md-4">
                        <label for="Name"> Endereço: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.endereco"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-2">
                        <label for="Name"> Número: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="inputNumero"
                          class="form-control"
                          v-model="entity.numero"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-3">
                        <label for="Name"> Complemento: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.complemento"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-3">
                        <label for="Name"> Bairro: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.bairro"
                          placeholder=""
                        />
                      </div>

                      <div class="col-md-3">
                        <label> Estado </label>
                        <v-select
                          :options="ufs"
                          :reduce="(ufs) => ufs.id"
                          :label="'uf_nome'"
                          v-on:input="getEstadoMunicipio"
                          v-model="entity.estado_id"
                          class="vselect"
                        />
                      </div>

                      <div class="col-md-3">
                        <label> Município </label>
                        <v-select
                          :options="municipios"
                          :reduce="(municipios) => municipios.id"
                          :label="'municipio_nome'"
                          v-model="entity.municipio_id"
                          class="vselect"
                        />
                      </div>

                      <div class="form-group col-md-3">
                        <label for="Name"> Ponto de Referência: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.ponto_referencia"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label for="Name"> Peso: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.peso"
                          placeholder=""
                          v-mask="'999'"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label for="Name"> Tipo de Peso: </label>
                        <select class="form-control" v-model="entity.tipo_peso">
                          <option value="KG">Quilogramas</option>
                          <option value="G">Gramas</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6">
                        <label>Tipo de Endereço</label>
                        <select
                          class="form-control"
                          v-model="entity.tipo_domicilio_id"
                        >
                          <option
                            v-for="tipo in tipos_domicilio"
                            :key="tipo.tipo_domicilio_id"
                            :value="tipo.tipo_domicilio_id"
                          >
                            {{ tipo.tipo_domicilio_nome }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="form-group col-md-6"
                        v-if="entity.tipo_domicilio_id == 3"
                      >
                        <label for="Name"> Outro Tipo de Domicilio: </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="entity.outro_tipo_domicilio"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col-md-6">
                        <label
                          >Possui dificuldade de acesso ao entrar ou sair do
                          domicílio?</label
                        >
                        <select
                          class="form-control"
                          v-model="entity.dificuldade_acesso_domicilio"
                        >
                          <option value="SIM">Sim</option>
                          <option value="NÃO">Não</option>
                        </select>
                      </div>
                      <div
                        class="col-md-6"
                        v-if="entity.dificuldade_acesso_domicilio == 'SIM'"
                      >
                        <label>Qual?</label>
                        <input
                          v-model="
                            entity.dificuldade_acesso_domicilio_descricao
                          "
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mt-1">
                        <label
                          >Existe dificuldade de acesso ao entrar ou sair ao
                          paciente?</label
                        >
                        <select
                          class="form-control"
                          v-model="entity.dificuldade_acesso_paciente"
                        >
                          <option value="SIM">Sim</option>
                          <option value="NÃO">Não</option>
                        </select>
                      </div>
                      <div
                        class="col-md-6 mt-1"
                        v-if="entity.dificuldade_acesso_paciente == 'SIM'"
                      >
                        <label>Qual?</label>
                        <input
                          v-model="entity.dificuldade_acesso_paciente_descricao"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-row mt-1">
                      <div class="form-group col-md-6">
                        <label for="Name"> Status: </label>
                        <select v-model="entity.status" class="form-control">
                          <option :value="1">Ativo</option>
                          <option :value="0">Inativo</option>
                        </select>
                      </div>
                      <div class="col-md-6" v-if="entity.status == 0">
                        <label>Justificação de Inatividade do Paciente</label>
                        <input
                          v-model="entity.justificativa"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/paciente" class="btn btn-back mr-1">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import Inputmask from "inputmask";

export default {
  data() {
    return {
      entity: {
        nome_paciente: "",
        nome_mae: "",
        idade: "",
        data_nascimento: "",
        sexo: "",
        raca: "",
        altura: "",
        peso: "",
        endereco: "",
        bairro: "",
        telefone: "",
        cpf: "",
        nome_social: "",
        nome_pai: "",
        tipo_peso: "",
        cns: "",
        numero: "",
        complemento: "",
        cep: "",
        ponto_referencia: "",
        tipo_domicilio_id: "",
        municipio_id: "",
        status: "1",
        outro_tipo_domicilio: "",
        dificuldade_acesso_domicilio_descricao: "",
        dificuldade_acesso_paciente_descricao: "",
        justificativa: "",
      },
      municipios: [],
      ufs: [],
      tipos_domicilio: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "paciente";

      if (self.entity.paciente_id) {
        self.entity._method = "PUT";
        api += "/" + self.entity.paciente_id;
      }

      axios
        .post(api, self.entity)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/paciente");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "paciente/" + id;

      axios
        .get(api)
        .then((response) => {
          self.entity = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUFs: function () {
      const self = this;
      let api =
        self.$store.state.api + "ufs?orderByAsc=uf_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.ufs = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getTipoDomicilio: function () {
      const self = this;
      let api =
        self.$store.state.api + "tipo_domicilio?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_domicilio = response.data.data;
          console.log(response.data.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(self.entity.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + self.entity.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }

            self.entity.endereco = endereco.logradouro;
            self.entity.bairro = endereco.bairro;
            self.entity.complemento = endereco.complemento;

            let api =
              self.$store.state.api +
              "municipios?municipio_codigo=" +
              endereco.ibge.substring(0, endereco.ibge.length - 1);

            axios
              .get(api)
              .then((response) => {
                self.entity.estado_id = response.data.data[0].uf_id;
                self.getEstadoMunicipio();
                self.entity.municipio_id = response.data.data[0].municipio_id;
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
              });

            $("#inputNumero").focus();
          }
        );
      }
    },

    getEstadoMunicipio() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    buscarCPF: function () {
      var self = this;

      self.naoLocalizado = false;

      self.$loading(true);

      var cpf = self.entity.cpf.replace(/\.|\-/g, "");

      let api = self.$store.state.api + "paciente/get-cpf";
      axios
        .post(api, { cpf: cpf })
        .then((response) => {
          var data = response.data;

          if (data.erro == "CPF não encontrado") {
            self.$loading(false);
            self.$message("Atenção!", "CPF não encontrado", "warning");
            return;
          }

          self.entity.nome_paciente = data.nome;
          self.entity.nome_mae = data.nomeMae;
          self.entity.nome_pai = data.nomePai;
          self.entity.data_nascimento = data.dataNascimento;
          self.entity.sexo = data.sexo;
          self.entity.cpf = data.cpf;
          self.entity.cns = data.cnsFederalDefinitivo;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message("Atenção!", "CPF não encontrado", "warning");
          self.$loading(false);
        });
    },

    // buscarCPF: function () {
    //   var self = this;

    //   self.naoLocalizado = false;

    //   var cpf = self.entity.cpf.replace(/\.|\-/g, "");

    //   $.getJSON(
    //     "http://sigap.it4d.com.br/index.php/pesquisaCpfSus?cpf=" + cpf,
    //     function (data) {
    //       if (data.erro == "CPF não encontrado") {
    //         return;
    //       }

    //       self.entity.nome_paciente = data.nome;
    //       self.entity.nome_mae = data.nomeMae;
    //       self.entity.nome_pai = data.nomePai;
    //       self.entity.data_nascimento = data.dataNascimento;
    //       self.entity.sexo = data.sexo;
    //       self.entity.cpf = data.cpf;
    //       self.entity.cns = data.cnsFederalDefinitivo;
    //     }
    //   );
    // },
  },
  mounted: function () {
    const self = this;

    const inputmask = new Inputmask({
      mask: ["(99) 9999-9999", "(99) 9 9999-9999"],
      keepStatic: true,
    });

    inputmask.mask(document.getElementById("telefone-celular"));

    self.getUFs();
    self.getTipoDomicilio();

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>